import {
  initGlobalState, 
  registerMicroApps, 
  runAfterFirstMounted, 
  setDefaultMountApp, 
  start 
} from "qiankun";

import render from "components/render";

// First Step is set a loading
render({ loading: true });

const loader = (loading) => render({loading});

// Register micro apps
registerMicroApps(
  [
    ...(process.env.REACT_APP_DASHBOARD_URL ? [
      {
        name: "Dashboard", // app name registered
        entry: process.env.REACT_APP_DASHBOARD_URL,
        container: "#subapp",
        loader,
        activeRule: "/dashboard",
      },
    ] : []),
    ...(process.env.REACT_APP_EMPLOYEE_URL ? [
        {
        name: "Employee",
        entry: process.env.REACT_APP_EMPLOYEE_URL,
        container: "#subapp",
        loader,
        activeRule: "/employee",
      },
    ] : []),
    ...(process.env.REACT_APP_ATTENDANCE_URL ? [
        {
        name: "Attendance",
        entry: process.env.REACT_APP_ATTENDANCE_URL,
        container: "#subapp",
        loader,
        activeRule: "/attendance",
      },
    ] : []),
    ...(process.env.REACT_APP_LEAVE_URL ? [
      {
        name: "Leave",
        entry: process.env.REACT_APP_LEAVE_URL,
        container: "#subapp",
        loader,
        activeRule: "/leave",
      },
    ] : []),
    ...(process.env.REACT_APP_SHIFT_URL ? [
      {
        name: "Shift Scheduling",
        entry: process.env.REACT_APP_SHIFT_URL,
        container: "#subapp",
        loader,
        activeRule: "/scheduling",
      },
    ] : []),
    ...(process.env.REACT_APP_PAYROLL_URL ? [
      {
        name: "Payroll",
        entry: process.env.REACT_APP_PAYROLL_URL,
        container: "#subapp",
        loader,
        activeRule: "/payroll",
      },
    ] : []),
  ],
  {
    beforeLoad: [
      (app) => {
        console.log('[LifeCycle] before load %c%s', 'color: green;', app.name);
      },
    ],
    beforeMount: [
      (app) => {
        console.log('[LifeCycle] before mount %c%s', 'color: green;', app.name);
      },
    ],
    afterMount: [
      (app) => {
        const favicon = document.querySelector('link[rel="icon"]');
        if (favicon !== null) {
          favicon.href = '/favicon-timhut.png';
        } else {
          const newFavicon = document.createElement('link');
          newFavicon.rel = 'icon';
          newFavicon.href = '/favicon-timhut.png';
          document.head.appendChild(newFavicon);
        }
        switch(app.name) {
          case 'Employee': {
            document.title = "TimHut - Employee";
            break;
          }
          case 'Attendance': {
            document.title = "TimHut - Attendance";
            break;
          }
          case 'Leave': {
            document.title = "TimHut - Leave";
            break;
          }
          case 'Scheduling': {
            document.title = "TimHut - Shift Scheduling";
            break;
          }
          case 'Payroll': {
            document.title = "TimHut - Payroll";
            break;
          }
          default: {
            break;
          }
        }
      },
    ],
    afterUnmount: [
      (app) => {
        console.log('[LifeCycle] after unmount %c%s', 'color: green;', app.name);
      },
    ],
  },
);

// Define initial global state
const initialState = {
  currentUser: JSON.parse(localStorage.getItem("currentUser")) || null,
  accessToken: localStorage.getItem("accessToken") || null,
  currentRole: localStorage.getItem("currentRole") || null
};

// Initialize global state and actions
const actions = initGlobalState(initialState);

actions.onGlobalStateChange((value, prev) => {
  console.log('[onGlobalStateChange]:', value, prev);
  localStorage.setItem("currentUser", value.currentUser ? JSON.stringify(value.currentUser) : null);
  localStorage.setItem("accessToken", value.accessToken ? value.accessToken : null);
  localStorage.setItem("currentRole", value.currentRole ? value.currentRole : null);
});

// Only set initial state if it's not already set
if (!initialState.currentUser && !initialState.accessToken && !initialState.currentRole) {
  actions.setGlobalState(initialState);
}

// Set default mount app
setDefaultMountApp('/dashboard');

// Start 
start();

runAfterFirstMounted(() => {
  console.log('[MainApp] first app mounted');
});